import { GET_CLUB_STORE_WALLET, GET_STORE_WALLET_TRANSACTIONS } from "./actionTypes";

const initialState = {
    club_store_wallet: [],
    store_wallet_transactions: {}
};

export default function(state = initialState, action) {
    // console.log(action.payload, 'payload get club stories');
    if(action.type === GET_CLUB_STORE_WALLET) {
        return { ...state, club_store_wallet: action.payload };
    } else if (action.type === GET_STORE_WALLET_TRANSACTIONS) {
        return { ...state, store_wallet_transactions: action.payload };
    } else {
        return state;
    }
}
