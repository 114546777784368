import { createSlice } from '@reduxjs/toolkit';
import * as foodSpotActions from './foodSpotActions';
import * as foodSpotReducerData from './foodSpotReducerData';

const initialState = {
  foodSpotCoordinate: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  foodSpotView: {
    loading: false,
    data: 'list',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  foodSpotDistance: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  foodSpotLiked: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  foodSpotCategory: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  bestFoodSpots: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  youMightLikeSpots: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  mustTryCitySpots: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  verifiedFoodSpots: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  categoryFoodSpots: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  topRatedFoodSpots: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  userReviewFoodSpots: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  reviewTypesFoodSpots: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  foodSptReviewMonths: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  submitFoodSpotReview: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  deleteFoodSpotReviewImage: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  submitMissingFoodSpot: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  foodSpotFormCities: {
    loading: false,
    data: 'map',
    message: 'Not Loaded',
    error: null,
    status: false,
  },
};

const foodSpotsSlice = createSlice({
  name: 'foodSpot',
  initialState,
  reducers: {
    clearFoodSpotWithCoordinates: foodSpotActions.clearFoodSpotWithCoordinates,
    setFoodSpotView: foodSpotActions.setFoodSpotView,
    clearFoodSpotDistance: (state) => foodSpotActions.clearFoodSpotDistance(state),
    clearFoodSpotLiked: (state) => foodSpotActions.clearFoodSpotLiked(state),
    clearFoodSpotCategory: (state) => foodSpotActions.clearFoodSpotCategory(state),
    clearBestFoodSpot: (state) => foodSpotActions.clearBestFoodSpot(state),
    clearYouMightLikeFoodSpot: (state) => foodSpotActions.clearYouMightLikeFoodSpot(state),
    clearMustTryCityFoodSpot: (state) => foodSpotActions.clearMustTryCityFoodSpot(state),
    clearVerifiedFoodSpot: (state) => foodSpotActions.clearVerifiedFoodSpot(state),
    clearCategoryFoodSpot: (state) => foodSpotActions.clearCategoryFoodSpot(state),
    clearTopRatedFoodSpot: (state) => foodSpotActions.clearTopRatedFoodSpot(state),
    clearUserReviewFoodSpot: (state) => foodSpotActions.clearUserReviewFoodSpot(state),
    clearReviewTypesFoodSpot: (state) => foodSpotActions.clearReviewTypesFoodSpot(state),
    clearFoodSpotReviewMonths: (state) => foodSpotActions.clearFoodSpotReviewMonths(state),
    clearSubmitFoodSpotReview: (state) => foodSpotActions.clearSubmitFoodSpotReview(state),
    clearDeleteFoodSpotReviewImage: (state) => foodSpotActions.clearDeleteFoodSpotReviewImage(state),
    clearSubmitFoodSpotMissing: (state) => foodSpotActions.clearSubmitFoodSpotMissing(state),
    clearFoodSpotFormCities: (state) => foodSpotActions.clearFoodSpotFormCities(state),
  },
  extraReducers: (builder) => {
    builder
      .addCase(foodSpotActions.getFoodSpotWithCoordinateRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotCoordinates(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getFoodSpotWithCoordinateRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotCoordinates(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getFoodSpotWithCoordinateRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotCoordinates(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.setFoodSpotViewRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotView(state, action, 'pending'),
      )
      .addCase(foodSpotActions.setFoodSpotViewRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotView(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.setFoodSpotViewRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotView(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getFoodSpotDistanceRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotDistance(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getFoodSpotDistanceRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotDistance(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getFoodSpotDistanceRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotDistance(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getFoodSpotLikedRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotLiked(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getFoodSpotLikedRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotLiked(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getFoodSpotLikedRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotLiked(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getFoodSpotCategoryRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotCategory(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getFoodSpotCategoryRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotCategory(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getFoodSpotCategoryRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotCategory(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getBestFoodSpotRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForBestFoodSpot(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getBestFoodSpotRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForBestFoodSpot(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getBestFoodSpotRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForBestFoodSpot(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getYouMightBeLikeFoodSpotRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForYouMightLikeFoodSpot(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getYouMightBeLikeFoodSpotRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForYouMightLikeFoodSpot(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getYouMightBeLikeFoodSpotRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForYouMightLikeFoodSpot(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getMustTryCityFoodSpotRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForMustTryFoodFoodSpot(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getMustTryCityFoodSpotRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForMustTryFoodFoodSpot(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getMustTryCityFoodSpotRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForMustTryFoodFoodSpot(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getVerifiedFoodSpotRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForVerifiedFoodSpot(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getVerifiedFoodSpotRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForVerifiedFoodSpot(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getVerifiedFoodSpotRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForVerifiedFoodSpot(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getCategoryFoodSpotRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForCategoryFoodSpot(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getCategoryFoodSpotRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForCategoryFoodSpot(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getCategoryFoodSpotRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForCategoryFoodSpot(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getTopRatedFoodSpotRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForTopRatedFoodSpot(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getTopRatedFoodSpotRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForTopRatedFoodSpot(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getTopRatedFoodSpotRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForTopRatedFoodSpot(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getUserReviewFoodSpotRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForUserReviewFoodSpot(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getUserReviewFoodSpotRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForUserReviewFoodSpot(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getUserReviewFoodSpotRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForUserReviewFoodSpot(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getReviewTypesFoodSpotRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForReviewTypeFoodSpot(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getReviewTypesFoodSpotRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForReviewTypeFoodSpot(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getReviewTypesFoodSpotRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForReviewTypeFoodSpot(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getReviewMonthsFoodSpotRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotReviewMonths(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getReviewMonthsFoodSpotRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotReviewMonths(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getReviewMonthsFoodSpotRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotReviewMonths(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.submitFoodSpotReviewRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotReviewSubmit(state, action, 'pending'),
      )
      .addCase(foodSpotActions.submitFoodSpotReviewRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotReviewSubmit(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.submitFoodSpotReviewRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotReviewSubmit(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.deleteFoodSpotReviewImageRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForDeleteFoodSpotReviewImage(state, action, 'pending'),
      )
      .addCase(foodSpotActions.deleteFoodSpotReviewImageRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForDeleteFoodSpotReviewImage(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.deleteFoodSpotReviewImageRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForDeleteFoodSpotReviewImage(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.submitMissingFoodSpotRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForSubmitFoodSpotMissing(state, action, 'pending'),
      )
      .addCase(foodSpotActions.submitMissingFoodSpotRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForSubmitFoodSpotMissing(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.submitMissingFoodSpotRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForSubmitFoodSpotMissing(state, action, 'rejected'),
      );

    builder
      .addCase(foodSpotActions.getFoodSpotFormCitiesRequest.pending, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotFormCities(state, action, 'pending'),
      )
      .addCase(foodSpotActions.getFoodSpotFormCitiesRequest.fulfilled, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotFormCities(state, action, 'fulfilled'),
      )
      .addCase(foodSpotActions.getFoodSpotFormCitiesRequest.rejected, (state, action) =>
        foodSpotReducerData.requestDataForFoodSpotFormCities(state, action, 'rejected'),
      );
  },
});

export const {
  clearFoodSpotWithCoordinates,
  setFoodSpotView,
  clearFoodSpotDistance,
  clearFoodSpotLiked,
  clearFoodSpotCategory,
  clearBestFoodSpot,
  clearYouMightLikeFoodSpot,
  clearMustTryCityFoodSpot,
  clearVerifiedFoodSpot,
  clearCategoryFoodSpot,
  clearTopRatedFoodSpot,
  clearUserReviewFoodSpot,
  clearFoodSpotReviewMonths,
  clearSubmitFoodSpotReview,
  clearDeleteFoodSpotReviewImage,
  clearSubmitFoodSpotMissing,
  clearFoodSpotFormCities,
} = foodSpotsSlice.actions;

export default foodSpotsSlice.reducer;
