import { createSlice } from "@reduxjs/toolkit";
import {
    CHANGE_USER_PASSWORD,
    DELETE_USER_ACCOUNT,
    GET_WALLET_TRANSACTIONS,
    LOGIN_OTP_USER,
    LOGIN_USER,
    LOGOUT_USER,
    PURCHASE_WALLET_TRANSACTIONS,
    PURCHASE_WALLET_TRANSACTION_STATUS,
    REGISTER_NEW_USER,
    REGISTER_OTP_USER,
    REGISTER_USER,
    RUNNING_ORDER,
    SEND_CUSTOM_OTP,
    SEND_OTP,
    SEND_PASSWORD_RESET_EMAIL,
    UPDATE_USER_DETAILS_IMAGE,
    UPDATE_USER_INFO,
    UPDATE_USER_PROFILE_IMAGE,
    USER_CASHFREE_PAYMENT,
    USER_CASHFREE_PAYMENT_STATUS,
    USER_DINE_IN_PAYMENT_COMPLETE,
    USER_DINE_IN_PAYMENT_STATUS,
    USER_DINING_COINS,
    USER_SUBSCRIPTION_PLANS,
    VERIFY_OTP,
    VERIFY_PASSWORD_RESET_OTP
} from "./actionTypes";
import * as userActions from "./actions";

const initialState = {
    user: [],
    running_order: false,
    wallet: [],
    purchase_wallet: {},
    reset_mail: [],
    validate_email_otp: [],
    change_password: [],
    user_subscription_plans: [],
    purchase_wallet_status: {},
    dining_coins: 0,
    profile_image_update: null,
    update_user: {},
    payment_dine_out: {},
    payment_dine_out_status: {},
    cashfree_payment_request: {},
    cashfree_payment_status: {},
    delete_user_account: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REGISTER_NEW_USER:
            return { ...state, user: action.payload };
        case LOGIN_OTP_USER:
            return { ...state, user: action.payload };
        case REGISTER_OTP_USER:
            return { ...state, user: action.payload };
        case SEND_CUSTOM_OTP:
            return { ...state, user: action.payload };
        case LOGIN_USER:
            return { ...state, user: action.payload };
        case REGISTER_USER:
            return { ...state, user: action.payload };
        case LOGOUT_USER:
            return { ...state, user: action.payload };
        case UPDATE_USER_INFO:
            return { ...state, user: action.payload };
        case SEND_OTP:
            return { ...state, user: action.payload };
        case VERIFY_OTP:
            return { ...state, user: action.payload };
        case RUNNING_ORDER:
            return { ...state, running_order: action.payload };
        case GET_WALLET_TRANSACTIONS:
            return { ...state, wallet: action.payload };
        case SEND_PASSWORD_RESET_EMAIL:
            return { ...state, reset_mail: action.payload };
        case VERIFY_PASSWORD_RESET_OTP:
            return { ...state, validate_email_otp: action.payload };
        case CHANGE_USER_PASSWORD:
            return { ...state, change_password: action.payload };
        case USER_SUBSCRIPTION_PLANS:
            return { ...state, user_subscription_plans: action.payload };
        case PURCHASE_WALLET_TRANSACTIONS:
            return { ...state, purchase_wallet: action.payload };
        case PURCHASE_WALLET_TRANSACTION_STATUS:
            return { ...state, purchase_wallet_status: action.payload };
        case USER_DINING_COINS:
            return { ...state, dining_coins: action.payload };
        case UPDATE_USER_PROFILE_IMAGE:
            return { ...state, profile_image_update: action.payload };
        case UPDATE_USER_DETAILS_IMAGE:
            return { ...state, update_user: action.payload };
        case USER_DINE_IN_PAYMENT_STATUS:
            return { ...state, payment_dine_out: action.payload };
        case USER_DINE_IN_PAYMENT_COMPLETE:
            return { ...state, payment_dine_out_status: action.payload };
        case USER_CASHFREE_PAYMENT:
            return { ...state, cashfree_payment_request: action.payload };
        case USER_CASHFREE_PAYMENT_STATUS:
            return { ...state, cashfree_payment_status: action.payload };
        case DELETE_USER_ACCOUNT:
            return { ...state, delete_user_account: action.payload };
        default:
            return state;
    }
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserData: (state) => userActions.clearUserData(state),
    },
    extraReducers: (builder) => {
    },
});

export const {
    clearUserData,
} = userSlice.actions;

// export default userSlice.reducer;