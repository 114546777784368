import { createSlice } from '@reduxjs/toolkit';
import * as dineInActions from './dineInActions';
import * as dineInReducerData from './dineInReducerData';
const initialState = {
  dineInCount: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  dineInCategory: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
  dineInFeatured: {
    loading: false,
    data: null,
    message: 'Not Loaded',
    error: null,
    status: false,
  },
};

const dineInSlice = createSlice({
  name: 'dineIns',
  initialState,
  reducers: {
    clearDineInCounts: (state) => dineInActions.clearDineInCounts(state),
    clearDineInCategories: (state) => dineInActions.clearDineInCategories(state),
    clearDineInFeatured: (state) => dineInActions.clearDineInFeatured(state),
  },
  extraReducers: (builder) => {
    builder
      .addCase(dineInActions.getDineInCountRequest.pending, (state, action) =>
        dineInReducerData.requestDataForDineInCountData(state, action, 'pending'),
      )
      .addCase(dineInActions.getDineInCountRequest.fulfilled, (state, action) =>
        dineInReducerData.requestDataForDineInCountData(state, action, 'fulfilled'),
      )
      .addCase(dineInActions.getDineInCountRequest.rejected, (state, action) =>
        dineInReducerData.requestDataForDineInCountData(state, action, 'rejected'),
      );

    builder
      .addCase(dineInActions.getDineInCategoryRequest.pending, (state, action) =>
        dineInReducerData.requestDataForDineInCategoryData(state, action, 'pending'),
      )
      .addCase(dineInActions.getDineInCategoryRequest.fulfilled, (state, action) =>
        dineInReducerData.requestDataForDineInCategoryData(state, action, 'fulfilled'),
      )
      .addCase(dineInActions.getDineInCategoryRequest.rejected, (state, action) =>
        dineInReducerData.requestDataForDineInCategoryData(state, action, 'rejected'),
      );

    builder
      .addCase(dineInActions.getDineInFeaturedRequest.pending, (state, action) =>
        dineInReducerData.requestDataForDineInCategoryData(state, action, 'pending'),
      )
      .addCase(dineInActions.getDineInFeaturedRequest.fulfilled, (state, action) =>
        dineInReducerData.requestDataForDineInCategoryData(state, action, 'fulfilled'),
      )
      .addCase(dineInActions.getDineInFeaturedRequest.rejected, (state, action) =>
        dineInReducerData.requestDataForDineInCategoryData(state, action, 'rejected'),
      );
  },
});

export const {
  clearDineInCounts,
  clearDineInCategories,
  clearDineInFeatured,
} = dineInSlice.actions;

export default dineInSlice.reducer;
