import {
    APPLY_OFFER,
    GET_FEEDBACK_QUESTIONS, GET_FREE_DELIVERY_STATUS, SAVE_FEEDBACK_ANSWERS, SAVE_INACTIVE_USER_FEEDBACK,
} from "./actionTypes";

const initialState = {
    questions: { status: false },
    save_answer: { status: false },
    inactive_user_feedback: { status: false },
    free_delivery_status: { status: false },
    apply_offer: { status: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FEEDBACK_QUESTIONS:
            return { ...state, questions: action.payload };
        case SAVE_FEEDBACK_ANSWERS:
            return { ...state, save_answer: action.payload };
        case SAVE_INACTIVE_USER_FEEDBACK:
            return { ...state, inactive_user_feedback: action.payload };
        case GET_FREE_DELIVERY_STATUS:
            return { ...state, free_delivery_status: action.payload };
        case APPLY_OFFER:
            return { ...state, apply_offer: action.payload };
        default:
            return state;
    }
}
