import { CLUB_STORE_MENUS } from "./actionTypes";

const initialState = {
    club_store_menus: []
};

export default function(state = initialState, action) {
    // console.log(action.payload, 'payload get club stories');
    if(action.type === CLUB_STORE_MENUS) {
        return { ...state, club_store_menus: action.payload };
    } else {
        return state;
    }
}
