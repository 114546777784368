import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../configs/website";

const defaultFoodSpots = {
  loading: false,
  data: null,
  message: 'Not Loaded',
  error: null,
  status: false,
};

export const clearFoodSpotWithCoordinates = (state) => {
  return { ...state, foodSpotCoordinate: { ...defaultFoodSpots } };
};

export const setFoodSpotView = (state) => {
  return { ...state, foodSpotView: { ...defaultFoodSpots, data: 'map' } };
};

export const clearFoodSpotDistance = (state) => {
  return { ...state, foodSpotDistance: { ...defaultFoodSpots } };
};

export const clearFoodSpotLiked = (state) => {
  return { ...state, foodSpotLiked: { ...defaultFoodSpots } };
};

export const clearFoodSpotCategory = (state) => {
  return { ...state, foodSpotCategory: { ...defaultFoodSpots } };
};

export const clearBestFoodSpot = (state) => {
  return { ...state, bestFoodSpots: { ...defaultFoodSpots } };
};

export const clearYouMightLikeFoodSpot = (state) => {
  return { ...state, youMightLikeSpots: { ...defaultFoodSpots } };
};

export const clearMustTryCityFoodSpot = (state) => {
  return { ...state, mustTryCitySpots: { ...defaultFoodSpots } };
};

export const clearVerifiedFoodSpot = (state) => {
  return { ...state, verifiedFoodSpots: { ...defaultFoodSpots } };
};

export const clearCategoryFoodSpot = (state) => {
  return { ...state, categoryFoodSpots: { ...defaultFoodSpots } };
};

export const clearTopRatedFoodSpot = (state) => {
  return { ...state, topRatedFoodSpots: { ...defaultFoodSpots } };
};

export const clearUserReviewFoodSpot = (state) => {
  return { ...state, userReviewFoodSpots: { ...defaultFoodSpots } };
};

export const clearReviewTypesFoodSpot = (state) => {
  return { ...state, reviewTypesFoodSpots: { ...defaultFoodSpots } };
};

export const clearFoodSpotReviewMonths = (state) => {
  return { ...state, foodSptReviewMonths: { ...defaultFoodSpots } };
};

export const clearSubmitFoodSpotReview = (state) => {
  return { ...state, submitFoodSpotReview: { ...defaultFoodSpots } };
};

export const clearDeleteFoodSpotReviewImage = (state) => {
  return { ...state, deleteFoodSpotReviewImage: { ...defaultFoodSpots } };
};

export const clearSubmitFoodSpotMissing = (state) => {
  return { ...state, submitMissingFoodSpot: { ...defaultFoodSpots } };
};

export const clearFoodSpotFormCities = (state) => {
  return { ...state, foodSpotFormCities: { ...defaultFoodSpots } };
};

export const getFoodSpotWithCoordinateRequest = createAsyncThunk('food-spots/get-food-spot-by-coordinates', async ({ data }) => {
  try {
    const foodSpotCoordinate = await axios.post(`${API_URL}/get-food-spot-coordinates`, data);
    return { ...foodSpotCoordinate.data };
  } catch (error) {
    return {};
  }
});

export const setFoodSpotViewRequest = createAsyncThunk('food-spots/set-food-spot-view', async ({ view }) => {
  try {
    return view;
  } catch (error) {
    return {};
  }
});

export const getFoodSpotDistanceRequest = createAsyncThunk('food-spots/get-food-spot-distance', async ({ data }) => {
  try {
    const foodSpotDistance = await axios.post(`${API_URL}/get-food-spot-distance`, data);
    return { ...foodSpotDistance.data };
  } catch (error) {
    return {};
  }
});

export const getFoodSpotLikedRequest = createAsyncThunk('food-spots/get-food-spot-liked-request', async ({ data }) => {
  try {
    const foodSpotLiked = await axios.post(`${API_URL}/get-food-spot-liked`, data);
    return { ...foodSpotLiked.data };
  } catch (error) {
    return {};
  }
});

export const getFoodSpotCategoryRequest = createAsyncThunk('food-spots/get-food-spot-category-request', async () => {
  try {
    const foodSpotCategory = await axios.post(`${API_URL}/yummy-spotteds-category`);
    return { ...foodSpotCategory.data };
  } catch (error) {
    return {};
  }
});

export const getBestFoodSpotRequest = createAsyncThunk('food-spots/get-best-food-spot-request', async ({ data }) => {
  try {
    const bestFoodSpots = await axios.post(`${API_URL}/yummy-spotteds-best-10`, data);
    return { ...bestFoodSpots.data };
  } catch (error) {
    return {};
  }
});

export const getYouMightBeLikeFoodSpotRequest = createAsyncThunk('food-spots/get-you-might-be-like-food-spot-request', async ({ data }) => {
  try {
    const youMightLikeSpots = await axios.post(`${API_URL}/yummy-spotteds-you-might-be-like`, data);
    return { ...youMightLikeSpots.data };
  } catch (error) {
    return {};
  }
});

export const getMustTryCityFoodSpotRequest = createAsyncThunk('food-spots/get-must-try-city-food-spot-request', async ({ data }) => {
  try {
    const mustTryCitySpots = await axios.post(`${API_URL}/yummy-spotteds-must-try-cities`, data);
    return { ...mustTryCitySpots.data };
  } catch (error) {
    return {};
  }
});

export const getVerifiedFoodSpotRequest = createAsyncThunk('food-spots/get-verified-food-spot-request', async ({ data }) => {
  try {
    const verifiedFoodSpots = await axios.post(`${API_URL}/yummy-spotteds-verifed`, data);
    return { ...verifiedFoodSpots.data };
  } catch (error) {
    return {};
  }
});

export const getCategoryFoodSpotRequest = createAsyncThunk('food-spots/get-food-spot-by-category-request', async ({ data }) => {
  try {
    const categoryFoodSpots = await axios.post(`${API_URL}/yummy-spotteds-by-category`, data);
    return { ...categoryFoodSpots.data };
  } catch (error) {
    return {};
  }
});

export const getTopRatedFoodSpotRequest = createAsyncThunk('food-spots/get-food-spot-top-rate-request', async ({ data }) => {
  try {
    const topRatedFoodSpots = await axios.post(`${API_URL}/yummy-spotteds-top-rated`, data);
    return { ...topRatedFoodSpots.data };
  } catch (error) {
    return {};
  }
});

export const getUserReviewFoodSpotRequest = createAsyncThunk('food-spots/get-user-review-food-spot-request', async ({ data }) => {
  try {
    const userReviewFoodSpots = await axios.post(`${API_URL}/yummy-spotteds-user-review`, data);
    return { ...userReviewFoodSpots.data };
  } catch (error) {
    return {};
  }
});

export const getReviewTypesFoodSpotRequest = createAsyncThunk('food-spots/get-review-types-food-spot-request', async () => {
  try {
    const reviewTypesFoodSpots = await axios.post(`${API_URL}/yummy-spotteds-user-review-types`);
    return { ...reviewTypesFoodSpots.data };
  } catch (error) {
    return {};
  }
});

export const getReviewMonthsFoodSpotRequest = createAsyncThunk('food-spots/get-review-months-food-spot-request', async () => {
  try {
    const foodSptReviewMonths = await axios.post(`${API_URL}/yummy-spotteds-review-months`);
    return { ...foodSptReviewMonths.data };
  } catch (error) {
    return {};
  }
});

export const submitFoodSpotReviewRequest = createAsyncThunk('food-spots/submit-food-spot-review-request', async ({ data }) => {
  try {
    const submitFoodSpotReview = await axios.post(`${API_URL}/yummy-spotteds-submit-review`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
    return { ...submitFoodSpotReview.data };
  } catch (error) {
    return {};
  }
});

export const deleteFoodSpotReviewImageRequest = createAsyncThunk('food-spots/submit-food-spot-review-image-request', async ({ data }) => {
  try {
    const delteFoodSpotReviewImage = await axios.post(`${API_URL}/yummy-spotteds-delete-review-image`, data);
    return { ...delteFoodSpotReviewImage.data };
  } catch (error) {
    return {};
  }
});

export const submitMissingFoodSpotRequest = createAsyncThunk('food-spots/submit-missing-food-spot-request', async ({ data }) => {
  try {
    const submitMissingFoodSpot = await axios.post(`${API_URL}/yummy-spotteds-submit-food-spot`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
    return { ...submitMissingFoodSpot.data };
  } catch (error) {
    return {};
  }
});

export const getFoodSpotFormCitiesRequest = createAsyncThunk('food-spots/get-food-spot-form-cities-request', async () => {
  try {
    const foodSpotFormCities = await axios.post(`${API_URL}/yummy-spot-city-list`, {
      city_list: true
    });
    return { ...foodSpotFormCities.data };
  } catch (error) {
    return {};
  }
});
