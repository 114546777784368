import {
	CHANGE_USER_AVATAR_URL,
	CHANGE_USER_PASSWORD_URL,
	CHECK_CURRENT_CITY_URL,
	CHECK_USER_RUNNING_ORDER_URL,
	DELETE_USER_ACCOUNT_URL,
	GET_WALLET_TRANSACTIONS_URL,
	LOGIN_OTP_USER_URL,
	LOGIN_PHONE_URL,
	LOGIN_USER_OTP_URL,
	LOGIN_USER_URL,
	PURCHASE_WALLET_TRANSACTIONS_URL,
	PURCHASE_WALLET_TRANSACTION_STATUS_URL,
	REGISTER_NEW_USER_URL,
	REGISTER_OTP_USER_URL,
	REGISTER_USER_URL,
	SEND_CUSTOM_OTP_URL,
	SEND_OTP_URL,
	SEND_PASSWORD_RESET_EMAIL_URL,
	UPDATE_USER_INFO_FAILUR_URL,
	UPDATE_USER_INFO_URL,
	UPDATE_USER_PROFILE_DETAILS_URL,
	UPDATE_USER_PROFILE_IMAGE_URL,
	USER_CASHFREE_PAYMENT_STATUS_URL,
	USER_CASHFREE_PAYMENT_URL,
	USER_DINE_IN_AMOUNT_URL,
	USER_SUBSCRIPTION_PLANS_URL,
	VERIFY_OTP_URL,
	VERIFY_PASSWORD_RESET_OTP_URL
} from "../../configs";
import {
	CHANGE_USER_PASSWORD,
	CHECK_USER_CITY_CHECK,
	DELETE_USER_ACCOUNT,
	GET_WALLET_TRANSACTIONS,
	LOGIN_OTP_USER,
	LOGIN_PHONE,
	LOGIN_USER,
	LOGIN_USER_OTP,
	LOGOUT_USER,
	PURCHASE_WALLET_TRANSACTIONS,
	PURCHASE_WALLET_TRANSACTION_STATUS,
	REGISTER_NEW_USER,
	REGISTER_OTP_USER,
	REGISTER_USER,
	RUNNING_ORDER,
	SEND_CUSTOM_OTP,
	SEND_OTP,
	SEND_PASSWORD_RESET_EMAIL,
	UPDATE_USER_DETAILS_IMAGE,
	UPDATE_USER_INFO,
	UPDATE_USER_PROFILE_IMAGE,
	USER_CASHFREE_PAYMENT,
	USER_CASHFREE_PAYMENT_STATUS,
	USER_DINE_IN_PAYMENT_COMPLETE,
	USER_DINE_IN_PAYMENT_STATUS,
	USER_DINING_COINS,
	USER_SUBSCRIPTION_PLANS,
	VERIFY_OTP,
	VERIFY_PASSWORD_RESET_OTP
} from "./actionTypes";

import axios from "axios";
import { getCurrentMainVersion, iOS, sendCustomerToken, sendCustomerToken2 } from "../../components/helpers/truncate";
import { USER_DINE_IN_PAYMENT_URL } from './../../configs/index';

export const defaultUserDatas = {
  loading: false,
  data: null,
  message: 'Not Loaded',
  error: null,
  status: false,
};

export const clearUserData = (state) => {
  return { ...state, otpRequest: { ...defaultUserDatas } };
};

export const loginUserOtp = (name, email, otp, accessToken, phone, provider, address) => async (dispatch) => {
	return axios.post(LOGIN_OTP_USER_URL, {
		name: name,
		email: email,
		otp: otp,
		accessToken: accessToken,
		phone: phone,
		provider: provider,
		address: address,
	})
		.then((response) => {
			const user = response.data;
			if (user.data && user.data.auth_token)
				localStorage.setItem('customerAccessToken', user.data.auth_token)
			return dispatch({ type: LOGIN_OTP_USER, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const registerUserOtp = (name, email, phone, otp, address, dob) => async (dispatch) => {
	return axios.post(REGISTER_OTP_USER_URL, {
		name: name,
		email: email,
		phone: phone,
		otp: otp,
		address: address,
		dob: dob,
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: REGISTER_OTP_USER, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
			return dispatch({ type: REGISTER_OTP_USER, payload: {} });
		});
};

export const registerNewUser = (name, email, phone, otp, address, username) => async (dispatch) => {
	return axios.post(REGISTER_NEW_USER_URL, {
		name: name,
		email: email,
		phone: phone.length === 10 ? '+91' + phone : phone,
		otp: otp,
		address: address,
		username: username
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: REGISTER_NEW_USER, payload: user.data });
		})
		.catch(function (error) {
			if (error.response && error.response.data)
				return dispatch({ type: REGISTER_NEW_USER, payload: error.response.data });
			else
				return dispatch({ type: REGISTER_NEW_USER, payload: error });
		});
};

export const sendCustomOtp = (phone, verify_token) => async (dispatch) => {
	return axios.post(SEND_CUSTOM_OTP_URL, {
		phone: phone,
		verify_token
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: SEND_CUSTOM_OTP, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const loginUser = (name, email, password, accessToken, phone, provider, address) => async (dispatch) => {
	axios.post(LOGIN_USER_URL, {
		name: name,
		email: email,
		password: password,
		accessToken: accessToken,
		phone: phone,
		provider: provider,
		address: address,
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: LOGIN_USER, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const registerUser = (name, email, phone, password, address) => async (dispatch) => {
	axios.post(REGISTER_USER_URL, {
		name: name,
		email: email,
		phone: phone,
		password: password,
		address: address,
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: REGISTER_USER, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const logoutUser = (user) => async (dispatch) => {
	user = {};
	const walletEmpty = [];

	//remove geoLocation and userSetAddress
	localStorage.removeItem("userSetAddress");
	localStorage.removeItem("customerAccessToken");
	localStorage.removeItem("geoLocation");
	localStorage.removeItem("lastSavedNotificationToken");
	localStorage.removeItem("last_city_api");
	localStorage.removeItem("city");
	localStorage.removeItem("city_is_active");
	localStorage.removeItem("city_listed");
	localStorage.removeItem("authKey");
	localStorage.removeItem("getStartedt");
	localStorage.setItem("logoutUser", 1);

	dispatch({
		type: LOGOUT_USER,
		payload: user,
	});
	dispatch({
		type: RUNNING_ORDER,
		payload: false,
	});
	dispatch({
		type: GET_WALLET_TRANSACTIONS,
		payload: walletEmpty,
	});
	return Function();
};

export const updateUserInfo = (user_id, token, unique_order_id) => async (dispatch) => {
	return axios.post(UPDATE_USER_INFO_URL, {
		token: token,
		user_id: user_id,
		unique_order_id: unique_order_id,
	})
		.then((response) => {
			const user = response.data;
			localStorage.setItem("authKey", user.data.auth_token);
			if (user.data?.default_address) {
				// const userSetAddress = {
				// 	lat: user.data.default_address.latitude,
				// 	lng: user.data.default_address.longitude,
				// 	address: user.data.default_address.address,
				// 	house: user.data.default_address.house,
				// 	tag: user.data.default_address.tag,
				// };
				// localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
				// // console.log('userSetAddress - set in geolocationpage');
			}
			if(user?.data?.auth_token) {
				sendCustomerToken(user?.data?.auth_token);
				sendCustomerToken2(user?.data?.auth_token);
			}
			return dispatch({ type: UPDATE_USER_INFO, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
			return dispatch({ type: UPDATE_USER_INFO, payload: {} });
		});
};

export const updateUserInfoByLogin = () => async (dispatch) => {
	return axios.post(UPDATE_USER_INFO_FAILUR_URL, {
		token: localStorage.getItem("authKey"),
	})
		.then((response) => {
			const user = response.data;
			// localStorage.setItem("authKey", user.data.auth_token);
			updateUserInfo(user.data.id, user.data.auth_token);
			// return dispatch({ type: UPDATE_USER_INFO, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
			// return dispatch({ type: UPDATE_USER_INFO, payload: {} });
		});
};

export const deleteAccount = (token) => async (dispatch) => {
	return axios.post(DELETE_USER_ACCOUNT_URL, {
		token
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: DELETE_USER_ACCOUNT, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const updateUserProfileImage = (token, file) => async (dispatch) => {
	var formData = new FormData();
	formData.append("token", token);
	formData.append("image", file);
	return axios.post(UPDATE_USER_PROFILE_IMAGE_URL, formData)
		.then((response) => {
			const profile_image_update = response.data;
			return dispatch({ type: UPDATE_USER_PROFILE_IMAGE, payload: profile_image_update });
		})
		.catch(function (error) {
			// // console.log(error, 'action error user details');
			const profile_image_update = error;
			return dispatch({ type: UPDATE_USER_PROFILE_IMAGE, payload: profile_image_update });
		});
};

export const updateUserProfileDetails = (token, data) => async (dispatch) => {
	return axios.post(UPDATE_USER_PROFILE_DETAILS_URL, {
		token: token,
		name: data.name,
		phone: data.phone,
		email: data.email,
		dob: data.dob,
	})
		.then((response) => {
			const update_user = response.data;
			return dispatch({ type: UPDATE_USER_DETAILS_IMAGE, payload: update_user });
		})
		.catch(function (error) {
			// console.log(error);
			return dispatch({ type: UPDATE_USER_DETAILS_IMAGE, payload: error });
		});
};

export const checkUserRunningOrder = (user_id, token) => async (dispatch) => {
	axios.post(CHECK_USER_RUNNING_ORDER_URL, {
		token: token,
		user_id: user_id,
	})
		.then((response) => {
			const running_order = response.data;
			return dispatch({ type: RUNNING_ORDER, payload: running_order });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const sendOtp = (email, phone, accessToken, provider) => async (dispatch) => {
	return axios.post(SEND_OTP_URL, {
		email: email,
		phone: phone,
		accessToken: accessToken,
		provider: provider,
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: SEND_OTP, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const verifyOtp = (phone, otp) => async (dispatch) => {
	axios.post(VERIFY_OTP_URL, {
		phone: phone,
		otp: otp,
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: VERIFY_OTP, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const verifyPhoneOtp = (phone, otp) => async (dispatch) => {
	axios.post(LOGIN_PHONE_URL, {
		phone: phone,
		otp: otp,
	})
		.then((response) => {
			const user = response.data;
			return dispatch({ type: LOGIN_PHONE, payload: user });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const getWalletTransactions = (token, user_id) => async (dispatch) => {
	axios.post(GET_WALLET_TRANSACTIONS_URL, {
		token: token,
		user_id: user_id,
	})
		.then((response) => {
			const wallet = response.data;
			return dispatch({ type: GET_WALLET_TRANSACTIONS, payload: wallet });
		})
		.catch(function (error) {
			// console.log(error);
		});
};
export const purchaseWalletTransactions = (token, amount, from_cart) => async (dispatch) => {
	return axios.post(PURCHASE_WALLET_TRANSACTIONS_URL, {
		token, amount, from_cart
	})
		.then((response) => {
			const purchase_wallet = response.data;
			return dispatch({ type: PURCHASE_WALLET_TRANSACTIONS, payload: purchase_wallet });
		})
		.catch(function (error) {
			// console.log(error);
		});
};
export const purchaseWalletStatus = (token, id) => async (dispatch) => {
	return axios.post(PURCHASE_WALLET_TRANSACTION_STATUS_URL, {
		token, id
	})
		.then((response) => {
			const purchase_wallet_status = response.data;
			return dispatch({ type: PURCHASE_WALLET_TRANSACTION_STATUS, payload: purchase_wallet_status });
		})
		.catch(function (error) {
			// console.log(error);
		});
};
export const sendPasswordResetMail = (email) => async (dispatch) => {
	axios.post(SEND_PASSWORD_RESET_EMAIL_URL, {
		email: email,
	})
		.then((response) => {
			const data = response.data;
			return dispatch({ type: SEND_PASSWORD_RESET_EMAIL, payload: data });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const sendPasswordResetPhone = (phone) => async (dispatch) => {
	axios.post(LOGIN_USER_OTP_URL, {
		phone: phone,
	})
		.then((response) => {
			const data = response.data;
			return dispatch({ type: LOGIN_USER_OTP, payload: data });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const verifyPasswordResetOtp = (email, code) => async (dispatch) => {
	axios.post(VERIFY_PASSWORD_RESET_OTP_URL, {
		email: email,
		code: code,
	})
		.then((response) => {
			const data = response.data;
			return dispatch({ type: VERIFY_PASSWORD_RESET_OTP, payload: data });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const changeUserPassword = (email, code, password) => async (dispatch) => {
	axios.post(CHANGE_USER_PASSWORD_URL, {
		email: email,
		code: code,
		password: password,
	})
		.then((response) => {
			const data = response.data;
			return dispatch({ type: CHANGE_USER_PASSWORD, payload: data });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const changeAvatar = (token, avatar) => () => {
	return axios.post(CHANGE_USER_AVATAR_URL, {
		token: token,
		avatar: avatar,
	})
		.then((response) => {
			const data = response.data;
			return data;
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const clubUserSubscriptionPlans = () => async (dispatch) => {
	axios.post(USER_SUBSCRIPTION_PLANS_URL)
		.then((response) => {
			const subscription_plans = response.data;
			return dispatch({ type: USER_SUBSCRIPTION_PLANS, payload: subscription_plans });
		})
		.catch(function (error) {
			// console.log(error);
		});
};

export const getDineOutCoins = (features, amount) => async (dispatch) => {
	const feature = features.find((feature) => feature.feature_list_id === 5);
	const coins = { online: parseFloat(feature.online_rate).toFixed(0), cod: parseFloat(feature.cash_rate).toFixed(0) };
	dispatch({ type: USER_DINING_COINS, payload: coins });
}

export const payDineAmount = (token, id) => async (dispatch) => {
	return axios.post(USER_DINE_IN_AMOUNT_URL, {
		token,
		id
	})
		.then((response) => {
			const payment_dine_out = response.data;
			return dispatch({ type: USER_DINE_IN_PAYMENT_STATUS, payload: payment_dine_out });
		})
		.catch(function (error) {
			// console.log(error);
		});
}

export const completeDinePayment = (token, trans_id, cf_order_id, data) => async (dispatch) => {
	return axios.post(USER_DINE_IN_PAYMENT_URL, {
		token,
		trans_id,
		cf_order_id,
		data
	})
		.then((response) => {
			const payment_dine_out_status = response.data;
			return dispatch({ type: USER_DINE_IN_PAYMENT_COMPLETE, payload: payment_dine_out_status });
		})
		.catch(function (error) {
			// console.log(error);
		});
}

export const requestForNativeCashfree = (token, type, id) => async (dispatch) => {
	let version = getCurrentMainVersion(localStorage.getItem("appversion")),
		is_ios = iOS() ? true : false;
	let is_react_native = is_ios ? version > 100 : version > 114;
	is_react_native = is_react_native ? 1 : 0;
	return axios.post(USER_CASHFREE_PAYMENT_URL, {
		token,
		type,
		id,
		is_react_native
	},
		{
			headers: {
				'api-token': 'sdbshdbjjaJHAJJNKA58884415151JJANAJA'
			}
		})
		.then((response) => {
			const cashfree_payment_request = response.data;
			return dispatch({ type: USER_CASHFREE_PAYMENT, payload: cashfree_payment_request });
		})
		.catch(function (error) {
			// console.log(error);
			return dispatch({ type: USER_CASHFREE_PAYMENT, payload: {} });
		});
}

export const statusForNativeCashfree = (id, result, token, typeId, type) => async (dispatch) => {
	return axios.post(USER_CASHFREE_PAYMENT_STATUS_URL, {
		id,
		result,
		token,
		typeId,
		type
	},
		{
			headers: {
				'api-token': 'sdbshdbjjaJHAJJNKA58884415151JJANAJA'
			}
		})
		.then((response) => {
			const cashfree_payment_status = response.data;
			return dispatch({ type: USER_CASHFREE_PAYMENT_STATUS, payload: cashfree_payment_status });
		})
		.catch(function (error) {
			// console.log(error);
		});
}

export const getCurrentCity = () => async (dispatch) => {
	return axios.post(CHECK_CURRENT_CITY_URL, {
		latitude: JSON.parse(localStorage.getItem("userSetAddress")).lat,
		longitude: JSON.parse(localStorage.getItem("userSetAddress")).lng,
	})
		.then((response) => {
			const user_city = response.data;
			return dispatch({ type: CHECK_USER_CITY_CHECK, payload: user_city });
		})
		.catch(function (error) {
			// console.log(error);
			return dispatch({ type: CHECK_USER_CITY_CHECK, payload: {} });
		});
}