export const CLUB_STORE = "CLUB_STORE";
export const CITIES = "CITIES";
export const DINE_OUTS = "DINE_OUTS";
export const CATEGORYWISE_DINE_OUTS = "CATEGORYWISE_DINE_OUTS";
export const SINGLE_DINE_INFO = "SINGLE_DINE_INFO";
export const DINE_OUT_PAYMENT = "DINE_OUT_PAYMENT";
export const DINE_PAYMENT_STATUS = "DINE_PAYMENT_STATUS";
export const DINE_OUT_BILLING = "DINE_OUT_BILLING";
export const DINE_OUT_SINGLE_INFO = "DINE_OUT_SINGLE_INFO";
export const GET_DINE_OUT_REVIEWS = "GET_DINE_OUT_REVIEWS";
export const GET_DINING_BANNER = "GET_DINING_BANNER";
