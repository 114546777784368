const createRequestDataReducer = (key) => (state, action, type = 'fulfilled') => {
  const newState = { ...state[key], loading: false, error: null };

  if (type === 'fulfilled') {
    newState.data = action.payload.data;
    newState.message = action.payload.message;
    newState.status = action.payload.status;
  } else if (type === 'pending') {
    newState.loading = true;
    newState.message = 'Not Loaded';
    newState.status = true;
  } else {
    newState.status = false;
    newState.error = action?.error?.message;
    newState.data = action?.error?.message;
  }

  return { ...state, [key]: { ...newState } };
};

export const getOtpRequestData = (state) => state?.otpUser?.otpRequest;
export const getOtpVerifyData = (state) => state?.otpUser?.otpVerify;
export const getOtpNewUserData = (state) => state?.otpUser?.otpNewUser;
export const getLoggedUserData = (state) => state?.otpUser?.loggedUserInfo;

export const requestDataForOtpRequest = createRequestDataReducer('otpRequest');
export const requestDataForOtpVerify = createRequestDataReducer('otpVerify');
export const requestDataForOtpNewuser = createRequestDataReducer('otpNewUser');
export const requestDataForLoggedUser = createRequestDataReducer('loggedUserInfo');
