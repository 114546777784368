export const getFoodSpotCoordinateData = (state) => state?.foodSpot?.foodSpotCoordinate;
export const getFoodSpotViewData = (state) => state?.foodSpot?.foodSpotView;
export const getFoodSpotDistanceData = (state) => state?.foodSpot?.foodSpotDistance;
export const getFoodSpotLikedData = (state) => state?.foodSpot?.foodSpotLiked;
export const getFoodSpotCategoryData = (state) => state?.foodSpot?.foodSpotCategory;
export const getBestFoodSpotData = (state) => state?.foodSpot?.bestFoodSpots;
export const getYouMightLikeFoodSpotData = (state) => state?.foodSpot?.youMightLikeSpots;
export const getMustTryCityFoodSpotData = (state) => state?.foodSpot?.mustTryCitySpots;
export const getVerifiedFoodSpotData = (state) => state?.foodSpot?.verifiedFoodSpots;
export const getCategoryFoodSpotData = (state) => state?.foodSpot?.categoryFoodSpots;
export const getTopRatedFoodSpotData = (state) => state?.foodSpot?.topRatedFoodSpots;
export const getUserReviewFoodSpotData = (state) => state?.foodSpot?.userReviewFoodSpots;
export const getReviewTypeFoodSpotData = (state) => state?.foodSpot?.reviewTypesFoodSpots;
export const getFoodSpotReviewMonthData = (state) => state?.foodSpot?.foodSptReviewMonths;
export const getSubmitFoodSpotReview = (state) => state?.foodSpot?.submitFoodSpotReview;
export const getDeleteFoodSpotReviewImage = (state) => state?.foodSpot?.deleteFoodSpotReviewImage;
export const getSubmitFoodSpotMissing = (state) => state?.foodSpot?.submitMissingFoodSpot;
export const getFoodSpotFormCities = (state) => state?.foodSpot?.foodSpotFormCities;

export const requestDataForFoodSpotCoordinates = (state, action, type = 'fulfilled') => {
  const foodSpotCoordinate = { ...state.foodSpotCoordinate, loading: false, error: null };

  if (type === 'fulfilled') {
    foodSpotCoordinate.data = action.payload.data;
    foodSpotCoordinate.message = action.payload.message;
    foodSpotCoordinate.status = action.payload.status;
  } else if (type === 'pending') {
    foodSpotCoordinate.loading = true;
    foodSpotCoordinate.message = 'Not Loaded';
    foodSpotCoordinate.status = true;
  } else {
    foodSpotCoordinate.status = false;
    foodSpotCoordinate.error = action?.error?.message;
    foodSpotCoordinate.data = action?.error?.message;
  }

  return { ...state, foodSpotCoordinate: { ...foodSpotCoordinate } };
};

export const requestDataForFoodSpotView = (state, action, type = 'fulfilled') => {
  const foodSpotView = { ...state.foodSpotView, loading: false, error: null };

  if (type === 'fulfilled') {
    foodSpotView.data = action.payload;
    foodSpotView.message = 'success';
    foodSpotView.status = true;
  } else if (type === 'pending') {
    foodSpotView.loading = true;
    foodSpotView.message = 'Not Loaded';
    foodSpotView.status = true;
  } else {
    foodSpotView.status = false;
    foodSpotView.error = action?.error?.message;
    foodSpotView.data = action?.error?.message;
  }

  return { ...state, foodSpotView: { ...foodSpotView } };
};

export const requestDataForFoodSpotDistance = (state, action, type = 'fulfilled') => {
  const foodSpotDistance = { ...state.foodSpotDistance, loading: false, error: null };

  if (type === 'fulfilled') {
    foodSpotDistance.data = action.payload;
    foodSpotDistance.message = 'success';
    foodSpotDistance.status = true;
  } else if (type === 'pending') {
    foodSpotDistance.loading = true;
    foodSpotDistance.message = 'Not Loaded';
    foodSpotDistance.status = true;
  } else {
    foodSpotDistance.status = false;
    foodSpotDistance.error = action?.error?.message;
    foodSpotDistance.data = action?.error?.message;
  }

  return { ...state, foodSpotDistance: { ...foodSpotDistance } };
};

export const requestDataForFoodSpotLiked = (state, action, type = 'fulfilled') => {
  const foodSpotLiked = { ...state.foodSpotLiked, loading: false, error: null };

  if (type === 'fulfilled') {
    foodSpotLiked.data = action.payload;
    foodSpotLiked.message = 'success';
    foodSpotLiked.status = true;
  } else if (type === 'pending') {
    foodSpotLiked.loading = true;
    foodSpotLiked.message = 'Not Loaded';
    foodSpotLiked.status = true;
  } else {
    foodSpotLiked.status = false;
    foodSpotLiked.error = action?.error?.message;
    foodSpotLiked.data = action?.error?.message;
  }

  return { ...state, foodSpotLiked: { ...foodSpotLiked } };
};

export const requestDataForFoodSpotCategory = (state, action, type = 'fulfilled') => {
  const foodSpotCategory = { ...state.foodSpotCategory, loading: false, error: null };

  if (type === 'fulfilled') {
    foodSpotCategory.data = action.payload.data;
    foodSpotCategory.message = action.payload.message;
    foodSpotCategory.status = action.payload.status;
  } else if (type === 'pending') {
    foodSpotCategory.loading = true;
    foodSpotCategory.message = 'Not Loaded';
    foodSpotCategory.status = true;
  } else {
    foodSpotCategory.status = false;
    foodSpotCategory.error = action?.error?.message;
    foodSpotCategory.data = action?.error?.message;
  }

  return { ...state, foodSpotCategory: { ...foodSpotCategory } };
};

export const requestDataForBestFoodSpot = (state, action, type = 'fulfilled') => {
  const bestFoodSpots = { ...state.bestFoodSpots, loading: false, error: null };

  if (type === 'fulfilled') {
    bestFoodSpots.data = action.payload.data;
    bestFoodSpots.message = action.payload.message;
    bestFoodSpots.status = action.payload.status;
  } else if (type === 'pending') {
    bestFoodSpots.loading = true;
    bestFoodSpots.message = 'Not Loaded';
    bestFoodSpots.status = true;
  } else {
    bestFoodSpots.status = false;
    bestFoodSpots.error = action?.error?.message;
    bestFoodSpots.data = action?.error?.message;
  }

  return { ...state, bestFoodSpots: { ...bestFoodSpots } };
};

export const requestDataForYouMightLikeFoodSpot = (state, action, type = 'fulfilled') => {
  const youMightLikeSpots = { ...state.youMightLikeSpots, loading: false, error: null };

  if (type === 'fulfilled') {
    youMightLikeSpots.data = action.payload.data;
    youMightLikeSpots.message = action.payload.message;
    youMightLikeSpots.status = action.payload.status;
  } else if (type === 'pending') {
    youMightLikeSpots.loading = true;
    youMightLikeSpots.message = 'Not Loaded';
    youMightLikeSpots.status = true;
  } else {
    youMightLikeSpots.status = false;
    youMightLikeSpots.error = action?.error?.message;
    youMightLikeSpots.data = action?.error?.message;
  }

  return { ...state, youMightLikeSpots: { ...youMightLikeSpots } };
};

export const requestDataForMustTryFoodFoodSpot = (state, action, type = 'fulfilled') => {
  const mustTryCitySpots = { ...state.mustTryCitySpots, loading: false, error: null };

  if (type === 'fulfilled') {
    mustTryCitySpots.data = action.payload.data;
    mustTryCitySpots.message = action.payload.message;
    mustTryCitySpots.status = action.payload.status;
  } else if (type === 'pending') {
    mustTryCitySpots.loading = true;
    mustTryCitySpots.message = 'Not Loaded';
    mustTryCitySpots.status = true;
  } else {
    mustTryCitySpots.status = false;
    mustTryCitySpots.error = action?.error?.message;
    mustTryCitySpots.data = action?.error?.message;
  }

  return { ...state, mustTryCitySpots: { ...mustTryCitySpots } };
};

export const requestDataForVerifiedFoodSpot = (state, action, type = 'fulfilled') => {
  const verifiedFoodSpots = { ...state.verifiedFoodSpots, loading: false, error: null };

  if (type === 'fulfilled') {
    verifiedFoodSpots.data = action.payload.data;
    verifiedFoodSpots.message = action.payload.message;
    verifiedFoodSpots.status = action.payload.status;
  } else if (type === 'pending') {
    verifiedFoodSpots.loading = true;
    verifiedFoodSpots.message = 'Not Loaded';
    verifiedFoodSpots.status = true;
  } else {
    verifiedFoodSpots.status = false;
    verifiedFoodSpots.error = action?.error?.message;
    verifiedFoodSpots.data = action?.error?.message;
  }

  return { ...state, verifiedFoodSpots: { ...verifiedFoodSpots } };
};

export const requestDataForCategoryFoodSpot = (state, action, type = 'fulfilled') => {
  const categoryFoodSpots = { ...state.categoryFoodSpots, loading: false, error: null };

  if (type === 'fulfilled') {
    categoryFoodSpots.data = action.payload.data;
    categoryFoodSpots.message = action.payload.message;
    categoryFoodSpots.status = action.payload.status;
  } else if (type === 'pending') {
    categoryFoodSpots.loading = true;
    categoryFoodSpots.message = 'Not Loaded';
    categoryFoodSpots.status = true;
  } else {
    categoryFoodSpots.status = false;
    categoryFoodSpots.error = action?.error?.message;
    categoryFoodSpots.data = action?.error?.message;
  }

  return { ...state, categoryFoodSpots: { ...categoryFoodSpots } };
};

export const requestDataForTopRatedFoodSpot = (state, action, type = 'fulfilled') => {
  const topRatedFoodSpots = { ...state.topRatedFoodSpots, loading: false, error: null };

  if (type === 'fulfilled') {
    topRatedFoodSpots.data = action.payload.data;
    topRatedFoodSpots.message = action.payload.message;
    topRatedFoodSpots.status = action.payload.status;
  } else if (type === 'pending') {
    topRatedFoodSpots.loading = true;
    topRatedFoodSpots.message = 'Not Loaded';
    topRatedFoodSpots.status = true;
  } else {
    topRatedFoodSpots.status = false;
    topRatedFoodSpots.error = action?.error?.message;
    topRatedFoodSpots.data = action?.error?.message;
  }

  return { ...state, topRatedFoodSpots: { ...topRatedFoodSpots } };
};

export const requestDataForUserReviewFoodSpot = (state, action, type = 'fulfilled') => {
  const userReviewFoodSpots = { ...state.userReviewFoodSpots, loading: false, error: null };

  if (type === 'fulfilled') {
    userReviewFoodSpots.data = action.payload.data;
    userReviewFoodSpots.message = action.payload.message;
    userReviewFoodSpots.status = action.payload.status;
  } else if (type === 'pending') {
    userReviewFoodSpots.loading = true;
    userReviewFoodSpots.message = 'Not Loaded';
    userReviewFoodSpots.status = true;
  } else {
    userReviewFoodSpots.status = false;
    userReviewFoodSpots.error = action?.error?.message;
    userReviewFoodSpots.data = action?.error?.message;
  }

  return { ...state, userReviewFoodSpots: { ...userReviewFoodSpots } };
};

export const requestDataForReviewTypeFoodSpot = (state, action, type = 'fulfilled') => {
  const reviewTypesFoodSpots = { ...state.reviewTypesFoodSpots, loading: false, error: null };

  if (type === 'fulfilled') {
    reviewTypesFoodSpots.data = action.payload.data;
    reviewTypesFoodSpots.message = action.payload.message;
    reviewTypesFoodSpots.status = action.payload.status;
  } else if (type === 'pending') {
    reviewTypesFoodSpots.loading = true;
    reviewTypesFoodSpots.message = 'Not Loaded';
    reviewTypesFoodSpots.status = true;
  } else {
    reviewTypesFoodSpots.status = false;
    reviewTypesFoodSpots.error = action?.error?.message;
    reviewTypesFoodSpots.data = action?.error?.message;
  }

  return { ...state, reviewTypesFoodSpots: { ...reviewTypesFoodSpots } };
};

export const requestDataForFoodSpotReviewMonths = (state, action, type = 'fulfilled') => {
  const foodSptReviewMonths = { ...state.foodSptReviewMonths, loading: false, error: null };

  if (type === 'fulfilled') {
    foodSptReviewMonths.data = action.payload.data;
    foodSptReviewMonths.message = action.payload.message;
    foodSptReviewMonths.status = action.payload.status;
  } else if (type === 'pending') {
    foodSptReviewMonths.loading = true;
    foodSptReviewMonths.message = 'Not Loaded';
    foodSptReviewMonths.status = true;
  } else {
    foodSptReviewMonths.status = false;
    foodSptReviewMonths.error = action?.error?.message;
    foodSptReviewMonths.data = action?.error?.message;
  }

  return { ...state, foodSptReviewMonths: { ...foodSptReviewMonths } };
};

export const requestDataForFoodSpotReviewSubmit = (state, action, type = 'fulfilled') => {
  const submitFoodSpotReview = { ...state.submitFoodSpotReview, loading: false, error: null };

  if (type === 'fulfilled') {
    submitFoodSpotReview.data = action.payload.data;
    submitFoodSpotReview.message = action.payload.message;
    submitFoodSpotReview.status = action.payload.status;
  } else if (type === 'pending') {
    submitFoodSpotReview.loading = true;
    submitFoodSpotReview.message = 'Not Loaded';
    submitFoodSpotReview.status = true;
  } else {
    submitFoodSpotReview.status = false;
    submitFoodSpotReview.error = action?.error?.message;
    submitFoodSpotReview.data = action?.error?.message;
  }

  return { ...state, submitFoodSpotReview: { ...submitFoodSpotReview } };
};

export const requestDataForDeleteFoodSpotReviewImage = (state, action, type = 'fulfilled') => {
  const deleteFoodSpotReviewImage = { ...state.deleteFoodSpotReviewImage, loading: false, error: null };

  if (type === 'fulfilled') {
    deleteFoodSpotReviewImage.data = action.payload.data;
    deleteFoodSpotReviewImage.message = action.payload.message;
    deleteFoodSpotReviewImage.status = action.payload.status;
  } else if (type === 'pending') {
    deleteFoodSpotReviewImage.loading = true;
    deleteFoodSpotReviewImage.message = 'Not Loaded';
    deleteFoodSpotReviewImage.status = true;
  } else {
    deleteFoodSpotReviewImage.status = false;
    deleteFoodSpotReviewImage.error = action?.error?.message;
    deleteFoodSpotReviewImage.data = action?.error?.message;
  }

  return { ...state, deleteFoodSpotReviewImage: { ...deleteFoodSpotReviewImage } };
};

export const requestDataForSubmitFoodSpotMissing = (state, action, type = 'fulfilled') => {
  const submitMissingFoodSpot = { ...state.submitMissingFoodSpot, loading: false, error: null };

  if (type === 'fulfilled') {
    submitMissingFoodSpot.data = action.payload.data;
    submitMissingFoodSpot.message = action.payload.message;
    submitMissingFoodSpot.status = action.payload.status;
  } else if (type === 'pending') {
    submitMissingFoodSpot.loading = true;
    submitMissingFoodSpot.message = 'Not Loaded';
    submitMissingFoodSpot.status = true;
  } else {
    submitMissingFoodSpot.status = false;
    submitMissingFoodSpot.error = action?.error?.message;
    submitMissingFoodSpot.data = action?.error?.message;
  }

  return { ...state, submitMissingFoodSpot: { ...submitMissingFoodSpot } };
};

export const requestDataForFoodSpotFormCities = (state, action, type = 'fulfilled') => {
  const foodSpotFormCities = { ...state.foodSpotFormCities, loading: false, error: null };

  if (type === 'fulfilled') {
    foodSpotFormCities.data = action.payload.data;
    foodSpotFormCities.message = action.payload.message;
    foodSpotFormCities.status = action.payload.status;
  } else if (type === 'pending') {
    foodSpotFormCities.loading = true;
    foodSpotFormCities.message = 'Not Loaded';
    foodSpotFormCities.status = true;
  } else {
    foodSpotFormCities.status = false;
    foodSpotFormCities.error = action?.error?.message;
    foodSpotFormCities.data = action?.error?.message;
  }

  return { ...state, foodSpotFormCities: { ...foodSpotFormCities } };
};
