import { CLUB_PLANS, GET_CLUB_USER_SUBSCRIBE, GET_CURRENT_COIN_RATE } from "./actionTypes";

const initialState = {
    club_plans: [],
    coin_rate: 0,
    user_subscription: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLUB_PLANS:
            return { ...state, club_plans: action.payload };
        case GET_CURRENT_COIN_RATE:
            return { ...state, coin_rate: action.payload };
        case GET_CLUB_USER_SUBSCRIBE:
            return { ...state, user_subscription: action.payload };
        default:
            return state;
    }
}