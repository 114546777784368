export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_OTP = "LOGIN_USER_OTP";
export const LOGIN_PHONE = "LOGIN_PHONE";

export const REGISTER_USER = "REGISTER_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const DELETE_USER_ACCOUNT = "DELETE_USER_ACCOUNT";
export const UPDATE_USER_PROFILE_IMAGE = "UPDATE_USER_PROFILE_IMAGE";
export const UPDATE_USER_DETAILS_IMAGE = "UPDATE_USER_DETAILS_IMAGE";
export const SEND_OTP = "SEND_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const RUNNING_ORDER = "RUNNING_ORDER";
export const GET_WALLET_TRANSACTIONS = "GET_WALLET_TRANSACTIONS";
export const PURCHASE_WALLET_TRANSACTIONS = "PURCHASE_WALLET_TRANSACTIONS";
export const PURCHASE_WALLET_TRANSACTION_STATUS = "PURCHASE_WALLET_TRANSACTION_STATUS";
export const SEND_PASSWORD_RESET_EMAIL = "SEND_PASSWORD_RESET_EMAIL";
export const VERIFY_PASSWORD_RESET_OTP = "VERIFY_PASSWORD_RESET_OTP";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";

export const LOGIN_OTP_USER = "LOGIN_OTP_USER";
export const REGISTER_OTP_USER = "REGISTER_OTP_USER";
export const REGISTER_NEW_USER = "REGISTER_NEW_USER";
export const SEND_CUSTOM_OTP = "SEND_CUSTOM_OTP";
export const  USER_SUBSCRIPTION_PLANS = "USER_SUBSCRIPTION_PLANS";
export const  USER_DINE_IN_PAYMENT_STATUS = "USER_DINE_IN_PAYMENT_STATUS";
export const  USER_DINE_IN_PAYMENT_COMPLETE = "USER_DINE_IN_PAYMENT_COMPLETE";
export const  USER_CASHFREE_PAYMENT = "USER_CASHFREE_PAYMENT";
export const  USER_CASHFREE_PAYMENT_STATUS = "USER_CASHFREE_PAYMENT_STATUS";
export const  CHECK_USER_CITY_CHECK = "CHECK_USER_CITY_CHECK";

export const USER_DINING_COINS = "USER_DINING_COINS";
