import { SUBSCRIPTION_PLAN_FEATURES, UPGRDADE_USER_CLUB_SUBSCRIBE, USER_CLUB_PLANS, USER_CLUB_SUBSCRIBE } from "./actionTypes";

const initialState = {
    user_club_plans: [],
    subscription: {},
    upgrade_subscription: {},
    subscription_features: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_CLUB_PLANS:
            return { ...state, user_club_plans: action.payload };
        case USER_CLUB_SUBSCRIBE:
            return { ...state, subscription: action.payload };
        case UPGRDADE_USER_CLUB_SUBSCRIBE:
            return { ...state, upgrade_subscription: action.payload };
        case SUBSCRIPTION_PLAN_FEATURES:
            return { ...state, subscription_features: action.payload};
        default:
            return state;
    }
}