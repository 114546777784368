import { RESTAURANT_PAYOUT_REPORTS, RESTAURANT_PAYOUT_REPORTS2 } from "./actionTypes";

const initialState = {
    restaurant_payout_reports: [],
    restaurant_payout_reports2: []
};

export default function(state = initialState, action) {
    // console.log(action.payload, 'payload get club stories');
    if(action.type === RESTAURANT_PAYOUT_REPORTS) {
        return { ...state, restaurant_payout_reports: action.payload };
    } else if(action.type === RESTAURANT_PAYOUT_REPORTS2) {
        return { ...state, restaurant_payout_reports2: action.payload };
    } else {
        return state;
    }
}
