import { LOGIN_OTP_CLUB_USER, VERIFY_OTP_CLUB_USER, LOGOUT_CLUB_USER, LOGIN_CLUB_USER_VENDOR_DASH, UPDATE_CLUB_USER_INFO, RESTAURANT_REVIEWS } from "./actionTypes";

const initialState = {
    club_user: [],
    order_history: [],
    reviews: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_OTP_CLUB_USER:
            return { ...state, club_user: action.payload };
        case VERIFY_OTP_CLUB_USER:
            return { ...state, club_user: action.payload };
        case LOGOUT_CLUB_USER:
            return { ...state, club_user: action.payload };
        case LOGIN_CLUB_USER_VENDOR_DASH:
            return { ...state, club_user: action.payload };
        case UPDATE_CLUB_USER_INFO:
            return { ...state, club_user: action.payload };
        case RESTAURANT_REVIEWS:
            return { ...state, reviews: action.payload };
        default:
            return state;
    }
}
