import { CLUB_CUSINES} from "./actionTypes";

const initialState = {
    club_cusines: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case CLUB_CUSINES:
            return { ...state, club_cusines: action.payload };
        default:
            return state;
    }
}